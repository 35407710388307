"use client";

import {
  ApolloNextAppProvider,
  ApolloClient,
  InMemoryCache,
} from "@apollo/experimental-nextjs-app-support";
import { httpLink, cacheConfig } from "./apollo-config";
import { ApolloLink, from } from "@apollo/client";
import { CookieEnum, getCookie } from "@/shared/utils/cookie";
import { onError } from "@apollo/client/link/error";
import { logout } from "../Authentication/utils";

const localeLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      locale: getCookie(CookieEnum.LANG) || null,
    },
  }));

  return forward(operation);
});

const logoutLink = onError(({ graphQLErrors }) => {
  if (
    graphQLErrors?.some(
      (error) =>
        // error.message.toLowerCase().includes("not_user_exist") ||
        error.message.toLowerCase().includes("unverified_jwt_token") ||
        error.message.toLowerCase().includes("access-denied")
    )
  ) {
    logout();
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getCookie(CookieEnum.AUTH_TOKEN);
  const authHeaders = token ? { authorization: `Bearer ${token}` } : {};
  operation.setContext(({ headers = {} }) => ({
    headers: { ...headers, ...authHeaders },
  }));
  return forward(operation);
});

function makeClient() {
  const link = from([logoutLink, authLink, localeLink, httpLink]);

  return new ApolloClient({
    cache: new InMemoryCache(cacheConfig),
    link,
  });
}

export const ApolloProvider = ({ children }: any) => {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
};
